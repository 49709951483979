@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: Pretendard-Regular, serif;
}

body {
	background-color: rgb(0, 0, 0);
}

@keyframes moveX {
	to {
		background-position:0 -125%;
	}
}

@font-face {
	font-family: 'Pretendard-Regular';
	src: url('./fonts/pretendard/PretendardVariable.ttf') format('woff');
	font-weight: 400;
	font-style: normal;
}

/*@font-face {*/
/*	font-family: 'GothicA1-B';*/
/*	src: url('./fonts/GothicA1-Bold.ttf') format('woff2');*/
/*	font-weight: 700;*/
/*	font-style: normal;*/
/*}*/

/*@font-face {*/
/*	font-family: 'GothicA1-R';*/
/*	src: url('./fonts/GothicA1-Regular.ttf') format('woff2');*/
/*	font-weight: normal;*/
/*	!*font-style: normal;*!*/
/*}*/

.info-text-bold {
	font-family: GothicA1-B, serif;
}

.info-text-regular {
	font-family: GothicA1-R, serif;
}
